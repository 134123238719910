import { AppRoutes } from 'src/routes/routeConstants/appRoutes';
import { ReactComponent as DASHBOARD_LIGHT_ICON } from 'src/assets/icons/sidebar/dashboard-icon-light.svg';
import { ReactComponent as USER_MANAGEMENT_LIGHT_ICON } from 'src/assets/icons/sidebar/user-management-icon-light.svg';
import { ReactComponent as ACTIVITY_LOG_LIGHT_ICON } from 'src/assets/icons/sidebar/activity-log-light.svg';
import { ReactComponent as LOGOU_LIGHT_ICON } from 'src/assets/icons/sidebar/logout-icon-light.svg';
import { ReactComponent as REPORT_LIGHT_ICON } from 'src/assets/icons/sidebar/reports-icon-light.svg';

const menuItems = [
  {
    key: AppRoutes.DASHBOARD,
    icon: <DASHBOARD_LIGHT_ICON />,
    label: 'Dashboard'
  },
  {
    key: AppRoutes.USER_MANAGEMENT,
    icon: <USER_MANAGEMENT_LIGHT_ICON />,
    label: 'User Management'
  },
  {
    key: AppRoutes.ACTIVITY_LOG,
    icon: <ACTIVITY_LOG_LIGHT_ICON />,
    label: 'Activity Log'
  },
  {
    key: AppRoutes.REPORTS,
    icon: <REPORT_LIGHT_ICON />,
    label: 'Reports'
  },
  {
    key: 'logout',
    icon: <LOGOU_LIGHT_ICON />,
    label: 'Logout'
  }
];

export default menuItems;
