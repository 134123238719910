import React, { FC } from 'react';
import { Col, Row } from 'antd';
import InputField from 'src/shared/components/InputField';
import { DropdownFieldWithFormik } from 'src/shared/components/DropdownField';
import roleOptions from './roleOptions';
import { useFormikContext } from 'formik';
import { User } from 'src/models/User/user.model';
import UploadPicture from 'src/shared/components/UploadPicture';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload';
import { Attachments } from 'src/models/Attachments/attachments.model';

interface UserFormProps {}

const UserForm: FC<UserFormProps> = () => {
  const { values, setFieldValue } = useFormikContext<User>();
  const { id } = values;

  const handleFileUploadChange = (file: UploadChangeParam<UploadFile>) => {
    let updatedFiles: Attachments | undefined;

    file.fileList.forEach((item) => {
      if (item.status === 'done') {
        updatedFiles = item.response;
      }
    });

    setFieldValue('photo', updatedFiles);
  };

  return (
    <div className="create-user-form">
      <Row>
        <Col span={24}>
          <InputField
            title="First Name"
            type="text"
            name="firstName"
            placeholder="Enter First Name"
          />
        </Col>
        <Col span={24}>
          <InputField
            title="Last Name"
            type="text"
            name="lastName"
            placeholder="Enter Last Name"
          />
        </Col>
        {id === undefined ? (
          <>
            <Col span={24}>
              <InputField
                title="Email ID"
                type="text"
                name="email"
                placeholder="Enter Email ID"
              />
            </Col>
            <Col span={24}>
              <DropdownFieldWithFormik
                title="Role"
                name="role"
                placeholder="Select Role"
                options={roleOptions}
                allowClear={false}
              />
            </Col>
          </>
        ) : (
          <Col span={24}>
            <UploadPicture
              title="Upload Picture"
              maxCount={1}
              name="photo"
              onChange={handleFileUploadChange}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default UserForm;
