import { serializable, alias, object, primitive } from 'serializr';
import { Tokens } from './newPasswordtoken.model';

export class ForgotPassword {
  @serializable(alias('new_password', primitive()))
  newPassword?: string;

  @serializable(alias('confirm_password', primitive()))
  confirmPassword?: string;

  @serializable(alias('tokens', object(Tokens)))
  tokens?: Tokens;
}
