import { io, Socket } from 'socket.io-client';
import { DefaultEventsMap } from '@socket.io/component-emitter';
import { Tokens } from 'src/enums/tokens';

const SOCKET_SERVER_ENDPOINT = process.env.REACT_APP_API_BASE_URL ?? '';
let socket: Socket<DefaultEventsMap, DefaultEventsMap>;

export const initSocket = () => {
  if (socket?.id) return socket;

  const accessToken = localStorage.getItem(Tokens.ACCESS_TOKEN);

  socket = io(SOCKET_SERVER_ENDPOINT, {
    query: { token: accessToken },
    reconnectionDelay: 10000, // defaults to 1000
    reconnectionDelayMax: 10000 // defaults to 5000,
  });

  return socket;
};
