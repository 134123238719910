import React, { ChangeEvent, FC, ReactNode } from 'react';
import { Field, ErrorMessage, FieldProps } from 'formik';
import { Input } from 'antd';
import Error from '../Error';
import Note from 'src/shared/components/Note';
import './inputField.scss';

interface InputFieldProps {
  name: string;
  type?: string;
  placeholder?: string;
  title?: string;
  note?: string;
  notetitle?: string;
  disabled?: boolean;
  addonBefore?: ReactNode;
  onChange?: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  prefix?: string;
  emptyLabel?: boolean;
  className?: string;
}

const InputField: FC<InputFieldProps> = (props) => {
  const {
    name,
    title,
    note,
    notetitle,
    type = 'text',
    onChange,
    prefix,
    emptyLabel,
    className
  } = props;

  const InputField =
    type === 'password'
      ? Input.Password
      : type === 'textarea'
        ? Input.TextArea
        : Input;

  return (
    <Field name={name}>
      {({ field, meta, form: { setFieldValue } }: FieldProps) => (
        <div className={`input-field ${className}`}>
          {(title || emptyLabel) && (
            <div className={`field-title ${emptyLabel && 'emptyLabel'}`}>
              {title || (emptyLabel && 'Empty')}
            </div>
          )}
          <InputField
            {...field}
            {...props}
            prefix={prefix}
            status={meta.touched && meta.error ? 'error' : ''}
            onChange={(values) => {
              setFieldValue(name, values?.target.value);
              onChange && onChange(values);
            }}
          />
          <div className="error-holder">
            <ErrorMessage name={name}>
              {(message: string) => {
                return <Error message={message} />;
              }}
            </ErrorMessage>
          </div>
          {note && <Note note={note} title={notetitle} className="mt-2" />}
        </div>
      )}
    </Field>
  );
};

export default InputField;
