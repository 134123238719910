import { serializable, alias, primitive, list, object } from 'serializr';
import { NotificationDetails } from '../NotificationDetails/NotificationDetails';
import { Pagination } from '../Pagination/pagination.model';

export class InAppNotification {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('created_at', primitive()))
  createdAt?: number;

  @serializable(alias('updated_at', primitive()))
  updatedAt?: number;

  @serializable(alias('notifications', list(object(NotificationDetails))))
  notifications?: NotificationDetails[] = [];

  @serializable(alias('unread_count', primitive()))
  unreadCount?: number;

  @serializable(alias('total_count', primitive()))
  totalCount?: number;

  @serializable(alias('meta', object(Pagination)))
  meta?: Pagination;
}
