import { Floorings } from '../Floorings/floorings.model';
import { LatestStatus } from '../LatestStatus/latestStatus.model';
import { Summary } from '../Summary/summary.model';
import { SubFormList } from '../SubFormList/subFormList.model';
import { Attachments } from '../Attachments/attachments.model';
import { Recycler } from '../Recycler/recycler.model';
import { Rental } from '../Rental/rental.model';
import { Pickup } from '../Pickup/pickup.model';
import { Project } from '../Project/project.model';
import { Channel } from '../Channel/channel.model';
import {
  serializable,
  alias,
  object,
  list,
  primitive,
  getDefaultModelSchema
} from 'serializr';
import { User } from '../User/user.model';
import { SubForm } from '../SubForm/subForm.model';
import { ContainerType } from '../ContainerType/containerType.model';
import { ApprovalDetails } from '../QuoteApprovalDetails/approvalDetails.model';
import { ActivityLogs } from '../ActivityLogs/activityLogs.model';

export class ReclamationForm {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('created_at', primitive()))
  createdAt?: number;

  @serializable(alias('updated_at', primitive()))
  updatedAt?: number;

  @serializable(alias('type', primitive()))
  type?: string;

  @serializable(alias('expires_at', primitive()))
  expiresAt?: number;

  @serializable(alias('reference_number', primitive()))
  referenceNumber?: string;

  @serializable(alias('company_name', primitive()))
  companyName?: string;

  @serializable(alias('account_number', primitive()))
  accountNumber?: string;

  @serializable(alias('channel', object(Channel)))
  channel?: Channel;

  @serializable(alias('channel_other', primitive()))
  channelOther?: string;

  @serializable(alias('is_contacted_by_representative', primitive()))
  isContactedByRepresentative?: boolean;

  @serializable(alias('get_contacted_by_representative', primitive()))
  getContactedByRepresentative?: boolean;

  @serializable(alias('project', object(Project)))
  project?: Project;

  @serializable(alias('pickup', object(Pickup)))
  pickup?: Pickup;

  @serializable(alias('is_rental_needed', primitive()))
  isRentalNeeded?: boolean;

  @serializable(alias('rental', object(Rental)))
  rental?: Rental;

  @serializable(alias('is_forklift_available', primitive()))
  isForkliftAvailable?: boolean;

  @serializable(alias('can_accommodate_trailer', primitive()))
  canAccommodateTrailer?: boolean;

  @serializable(alias('notes', primitive()))
  notes?: string;

  @serializable(alias('start_on', primitive()))
  startOn?: object;

  @serializable(alias('customer', object(User)))
  customer?: User;

  @serializable(alias('representative', object(User)))
  representative?: User;

  @serializable(alias('assigned_admin', object(User)))
  assignedAdmin?: User;

  @serializable(alias('pending_at', primitive()))
  pendingAt?: string;

  @serializable(alias('project_type', primitive()))
  projectType?: string;

  @serializable(alias('admin_notes', primitive()))
  adminNotes?: string;

  @serializable(alias('cre_firm_name', primitive()))
  creFirmName?: string;

  @serializable(alias('cre_firm_id', primitive()))
  creFirmId?: string;

  @serializable(alias('facilitator_name', primitive()))
  facilitatorName?: string;

  @serializable(alias('facilitator_id', primitive()))
  facilitatorId?: string;

  @serializable(alias('end_user_name', primitive()))
  endUserName?: string;

  @serializable(alias('end_user_id', primitive()))
  endUserId?: string;

  @serializable(alias('scenario_name', primitive()))
  scenarioName?: string;

  @serializable(alias('total_loads', primitive()))
  totalLoads?: number;

  @serializable(alias('completed_loads', primitive()))
  completedLoads?: number;

  @serializable(alias('total_bundle', primitive()))
  totalBundle?: number;

  @serializable(alias('container_size', primitive()))
  containerSize?: number;

  @serializable(alias('rental_days', primitive()))
  rentalDays?: number;

  @serializable(alias('cost_per_load', primitive()))
  costPerLoad?: number;

  @serializable(alias('cost_per_unit', primitive()))
  costPerUnit?: number;

  @serializable(alias('carrier_name', primitive()))
  carrierName?: string;

  @serializable(alias('recycler', object(Recycler)))
  recycler?: Recycler;

  @serializable(alias('admin_cost', primitive()))
  adminCost?: number;

  @serializable(alias('additional_fee_unit', primitive()))
  additionalFeeUnit?: string;

  @serializable(alias('additional_fee', primitive()))
  additionalFee?: number;

  @serializable(alias('photos', list(object(Attachments))))
  photos?: Attachments[] = [];

  @serializable(alias('attachments', list(object(Attachments))))
  attachments?: Attachments[] = [];

  @serializable(alias('project_number', primitive()))
  projectNumber?: string;

  @serializable(alias('parent_form_id', primitive()))
  parentProjectNumber?: string;

  @serializable(alias('sub_form_list', list(object(SubFormList))))
  subFormLists?: SubFormList[] = [];

  @serializable(alias('summary', object(Summary)))
  summary?: Summary;

  @serializable(alias('latest_status', object(LatestStatus)))
  latestStatus?: LatestStatus;

  @serializable(alias('floorings', list(object(Floorings))))
  floorings?: Floorings[] = [];

  @serializable(alias('sub_forms', list(object(SubForm))))
  children?: SubForm[] = [];

  @serializable(alias('container_type', object(ContainerType)))
  containerType?: ContainerType;

  @serializable(alias('logistics_container_type', object(ContainerType)))
  logisticsContainerType?: ContainerType;

  @serializable(alias('logistics_partner', primitive()))
  logisticsPartner?: string;

  @serializable(alias('approval_details', object(ApprovalDetails)))
  approvalDetails?: ApprovalDetails;

  @serializable(alias('quoted_on', primitive()))
  quotedOn?: number;

  @serializable(alias('special_instruction', primitive()))
  specialInstruction?: string;

  @serializable(alias('recycler_address', primitive()))
  recyclerAddress?: string;

  @serializable(alias('recycling_cost', primitive()))
  recyclingCost?: number;

  @serializable(alias('is_manual', primitive()))
  isManualPrice?: boolean;

  @serializable(alias('unit', primitive()))
  unit?: string;

  @serializable(alias('manufacturer', primitive()))
  manufacturer?: string;

  newScenario?: string;

  @serializable(alias('has_sub_form', primitive()))
  hasSubForm?: boolean;

  @serializable(alias('parent_id', primitive()))
  parentId?: number;

  activityLogs?: ActivityLogs[] = [];

  constructor() {
    getDefaultModelSchema<ReclamationForm>(
      ReclamationForm
    )!.props.activityLogs = alias('activity_logs', list(object(ActivityLogs)));
  }
}
