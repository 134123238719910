import { serializable, alias, primitive } from 'serializr';

export class Pagination {
  @serializable(alias('total', primitive()))
  total: number = 0;

  @serializable(alias('limit', primitive()))
  limit: number = 20;

  @serializable(alias('total_page', primitive()))
  totalPage?: number;

  @serializable(alias('current_page', primitive()))
  currentPage: number = 1;

  @serializable(alias('next_page', primitive()))
  nextPage?: number;

  @serializable(alias('previous_page', primitive()))
  previousPage?: number;

  @serializable(alias('has_next_page', primitive()))
  hasNextPage?: boolean;

  @serializable(alias('has_previous_page', primitive()))
  hasPreviousPage?: boolean;
}
