import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ApiRoutes } from '../routes/routeConstants/apiRoutes';
import Notification from '../shared/components/Notification';
import { NotificationTypes } from '../enums/notificationTypes';
import { Tokens } from 'src/enums/tokens';
import { AuthType } from 'src/enums/authType';
import { AppRoutes } from 'src/routes/routeConstants/appRoutes';

interface IRequestQueue {
  resolve: (val: unknown) => void;
  reject: (reason: unknown) => void;
}

let isTokenValid = true;

let queuedRequests: IRequestQueue[] = [];

const processQueue = (error?: Error, token = '') => {
  queuedRequests.forEach(({ resolve, reject }) =>
    error ? reject(error) : resolve(token)
  );

  queuedRequests = [];
};

const getHeaders = (): AxiosRequestConfig['headers'] => {
  const accessToken = localStorage.getItem(Tokens.ACCESS_TOKEN);
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken || ''}`
  };
};

const axiosInstance: AxiosInstance = axios.create({
  baseURL: ApiRoutes.BASE_URL,
  timeout: 20000
});

axiosInstance.interceptors.request.use((config) => {
  // @ts-expect-error "seting configuration headers"
  config.headers = {
    ...getHeaders(),
    ...config.headers
  };
  return config;
});

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error) => {
    const { response, config, _retry: RE_TRIED } = error;
    const logout = async () => {
      localStorage.clear();
      sessionStorage.clear();
      window.location.replace(AppRoutes.LOGIN);
    };
    if (response && response.status === 401) {
      if (RE_TRIED) return logout();
      if (!isTokenValid) {
        return new Promise((resolve, reject) => {
          queuedRequests.push({ resolve, reject });
        })
          .then((token) => {
            config.headers['Authorization'] = `bearer ${token}`;
            return axios(config);
          })
          .catch((ex) => Promise.reject(ex));
      }
      config._retry = true;
      isTokenValid = false;
      const refreshToken = localStorage.getItem(Tokens.REFRESH_TOKEN);
      const payload = {
        user: { auth_type: AuthType.TOKEN, tokens: { refresh: refreshToken } }
      };
      return axios
        .post(ApiRoutes.BASE_URL + ApiRoutes.USER_LOGIN, payload)
        .then((response) => {
          if (response.status >= 200 && response.status <= 299) {
            const responseData = response.data['user'];
            localStorage.setItem(
              Tokens.REFRESH_TOKEN,
              responseData?.tokens.refresh
            );
            localStorage.setItem(
              Tokens.ACCESS_TOKEN,
              responseData?.tokens.access
            );
            const newRequestConfig = {
              ...config,
              headers: {
                ...config.headers,
                Authorization: `Bearer ${responseData?.tokens.access || ''}`
              }
            };
            processQueue(undefined, responseData?.tokens.access);

            return axios(newRequestConfig);
          }
        })
        .catch((error) => {
          processQueue(error);
          logout();
        })
        .finally(() => {
          isTokenValid = true;
        });
    }
    Notification({
      message: 'Error',
      description: response.data.message || 'Something went wrong',
      type: NotificationTypes.ERROR
    });
    return Promise.reject(error);
  }
);

export default axiosInstance;
