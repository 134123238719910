import { SelectProps } from 'antd';

const roleOptions: SelectProps['options'] = [
  {
    label: 'Admin',
    value: 'Admin'
  },
  {
    label: 'Logistics',
    value: 'Logistics'
  }
];

export default roleOptions;
