import React, { FC, ReactNode, Suspense } from 'react';
import Spinner from '../Spinner';

interface LazySuspenseProps {
  children: ReactNode;
}

const LazySuspense: FC<LazySuspenseProps> = (props) => {
  const { children } = props;

  return (
    <Suspense
      fallback={
        <Spinner
          isLoadingOutlined={true}
          tip="Please wait"
          size="large"
          color="primary"
          theme="light"
          fullscreen={true}
        ></Spinner>
      }
    >
      {children}
    </Suspense>
  );
};

export default LazySuspense;
