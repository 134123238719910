import { ContainerType } from '../ContainerType/containerType.model';

import { serializable, alias, object, primitive } from 'serializr';

export class Rental {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('created_at', primitive()))
  createdAt?: number;

  @serializable(alias('updated_at', primitive()))
  updatedAt?: number;

  @serializable(alias('pick_date', primitive()))
  pickDate?: number;

  @serializable(alias('drop_date', primitive()))
  dropDate?: number;

  @serializable(alias('schedule', primitive()))
  schedule?: string;

  @serializable(alias('container_type', object(ContainerType)))
  containerType?: ContainerType;
}
