import { RcFile } from 'antd/lib/upload';

interface ReadAsArrayBufferResult {
  binaryData: ArrayBuffer;
  originalFile: File;
}

const readDataAsArrayBuffer = (
  file: RcFile | Blob
): Promise<ReadAsArrayBufferResult> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = (event: ProgressEvent<FileReader>) => {
      const binaryData = event.target?.result as ArrayBuffer;

      if (binaryData) {
        const originalFile =
          file instanceof File
            ? file
            : new File([file], file instanceof Blob ? 'blobfile' : 'unknown', {
                type: file.type
              });

        const result: ReadAsArrayBufferResult = {
          binaryData,
          originalFile
        };

        resolve(result);
      } else {
        reject(new Error('Failed to read file as ArrayBuffer'));
      }
    };

    fileReader.onerror = (error) => {
      reject(error);
    };

    fileReader.readAsArrayBuffer(file);
  });
};

export default readDataAsArrayBuffer;
