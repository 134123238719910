import { Choice } from '../Choice/choice.model';

import { Question } from '../Question/question.model';

import { serializable, alias, object, primitive } from 'serializr';

export class Answers {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('created_at', primitive()))
  createdAt?: number;

  @serializable(alias('updated_at', primitive()))
  updatedAt?: number;

  @serializable(alias('question', object(Question)))
  question?: Question;

  @serializable(alias('answer', primitive()))
  answer?: number | string;

  @serializable(alias('choice', object(Choice)))
  choice?: Choice;

  @serializable(alias('area_in_sy', primitive()))
  areaInSy?: number | string;

  @serializable(alias('weight_in_pound', primitive()))
  weightInPounds?: number;
}
