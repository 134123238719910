import { useState } from 'react';
import { deserialize, serialize } from 'serializr';
import axiosInstance from '../../interceptor/axiosInstance';
import { ApiRoutes } from './../../routes/routeConstants/apiRoutes';
import { LoginUser } from 'src/models/Login/loginuser.model';
import { User } from 'src/models/User/user.model';
import { AuthContext } from 'src/context/AuthContext';
import { AppRoutes } from 'src/routes/routeConstants/appRoutes';
import { useRedirect } from 'src/shared/hooks/useRedirect';
import { Tokens } from 'src/enums/tokens';
import { ForgotPassword } from 'src/models/Setpassword/forgotPassword.model';
import Notification from 'src/shared/components/Notification';
import { NotificationTypes } from 'src/enums/notificationTypes';
import { AuthType } from 'src/enums/authType';
import { initSocket } from 'src/shared/utils/initSocket';

const AuthService = () => {
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const { setAuthenticated, removeAuthenticated } = AuthContext();

  const { changePath } = useRedirect();

  const login = async (user: LoginUser) => {
    try {
      setButtonLoading(true);
      const payload = { user: serialize(LoginUser, user) };
      const response = await axiosInstance.post(ApiRoutes.USER_LOGIN, payload);
      const responseData = response.data['user'];
      const data = deserialize(User, responseData);
      if (Array.isArray(user.rememberPassword)) {
        if (user.rememberPassword[0]) {
          localStorage.setItem(Tokens.EMAIL, user.email as string);
        }
      }
      setAuthenticated(data, responseData?.tokens.access);
      localStorage.setItem(Tokens.REFRESH_TOKEN, responseData?.tokens.refresh);
      localStorage.setItem(Tokens.ACCESS_TOKEN, responseData?.tokens.access);
      changePath(`${AppRoutes.DASHBOARD}`);

      // Initializing socket connection on Logging in
      initSocket();
    } catch (error) {
      console.log('Error is ', error);
    } finally {
      setButtonLoading(false);
    }
  };

  const logout = () => {
    localStorage.removeItem(Tokens.ACCESS_TOKEN);
    localStorage.removeItem(Tokens.REFRESH_TOKEN);
    removeAuthenticated();
    changePath(AppRoutes.LOGIN);
  };

  const refreshUser = async () => {
    try {
      const refreshToken = localStorage.getItem(Tokens.REFRESH_TOKEN);
      const payload = {
        user: { auth_type: AuthType.TOKEN, tokens: { refresh: refreshToken } }
      };
      const response = await axiosInstance.post(ApiRoutes.USER_LOGIN, payload);
      const responseData = response.data['user'];
      const data = deserialize(User, responseData);
      setAuthenticated(data, responseData?.tokens.access);
      localStorage.setItem(Tokens.REFRESH_TOKEN, responseData?.tokens.refresh);
    } catch (error) {
      console.log('Error is ' + error);
      changePath(AppRoutes.LOGIN);
    }
  };

  const resetPassword = async (user: ForgotPassword) => {
    try {
      setButtonLoading(true);
      const payload = { user: serialize(ForgotPassword, user) };
      const response = await axiosInstance.post(
        ApiRoutes.SET_PASSWORD,
        payload
      );
      const data = response.data['message'];
      Notification({
        message: data ?? '',
        description: '',
        type: NotificationTypes.SUCCESS
      });
      changePath(AppRoutes.LOGIN);
    } catch (error) {
      console.log('Error is', error);
    } finally {
      setButtonLoading(false);
    }
  };

  const forgotPassword = async (email: string | undefined) => {
    try {
      setButtonLoading(true);
      const payload = { user: { email } };
      const response = await axiosInstance.post(
        ApiRoutes.FORGOT_PASSWORD,
        payload
      );
      const responseMessage = response.data['message'];
      Notification({
        message: responseMessage ?? '',
        description: '',
        type: NotificationTypes.SUCCESS
      });
    } catch (error) {
      throw new Error('Error is' + error);
    } finally {
      setButtonLoading(false);
    }
  };

  return {
    buttonLoading,
    login,
    logout,
    resetPassword,
    forgotPassword,
    refreshUser
  };
};

export default AuthService;
