import { LatestStatus } from '../LatestStatus/latestStatus.model';

import { serializable, alias, object, primitive } from 'serializr';
import { Project } from '../Project/project.model';
import { User } from '../User/user.model';
import { Recycler } from '../Recycler/recycler.model';

export class SubForm {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('created_at', primitive()))
  createdAt?: number;

  @serializable(alias('reference_number', primitive()))
  referenceNumber?: string;

  @serializable(alias('project', object(Project)))
  project?: Project;

  @serializable(alias('customer', object(User)))
  customer?: User;

  @serializable(alias('assigned_admin', object(User)))
  assignedAdmin?: User;

  @serializable(alias('pending_at', primitive()))
  pendingAt?: string;

  @serializable(alias('total_loads', primitive()))
  totalLoads?: number;

  @serializable(alias('completed_loads', primitive()))
  completedLoads?: number;

  @serializable(alias('recycler', object(Recycler)))
  recycler?: Recycler;

  @serializable(alias('project_number', primitive()))
  projectNumber?: string;

  @serializable(alias('latest_status', object(LatestStatus)))
  latestStatus?: LatestStatus;
}
