import { serializable, alias, primitive, list, object } from 'serializr';
import { Options } from '../Options/options.model';

export class Question {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('created_at', primitive()))
  createdAt?: number;

  @serializable(alias('updated_at', primitive()))
  updatedAt?: number;

  @serializable(alias('title', primitive()))
  title?: string;

  @serializable(alias('description', primitive()))
  description?: object;

  @serializable(alias('type', primitive()))
  type?: string;

  @serializable(alias('is_required', primitive()))
  isRequired?: boolean;

  @serializable(alias('is_area_field', primitive()))
  isAreaField?: boolean;

  @serializable(alias('options', list(object(Options))))
  options?: Options[] = [];
}
