import { useState } from 'react';
import { deserialize } from 'serializr';
import axiosInstance from '../../interceptor/axiosInstance';
import { ApiRoutes } from './../../routes/routeConstants/apiRoutes';
import { RcFile } from 'rc-upload/lib/interface';
import readDataAsArrayBuffer from 'src/shared/utils/readDataAsArrayBuffer';
import { Attachments } from '../../models/Attachments/attachments.model';

const FileUploadService = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const getPresignedUrl = async (attachmentName: string) => {
    try {
      setLoading(true);
      const payload = {
        attachment: {
          name: attachmentName
        }
      };

      const response = await axiosInstance.post(
        ApiRoutes.ATTACHMENT_PRESIGNED_URL,
        payload
      );

      const data = deserialize(
        Attachments,
        response.data.attachment
      ) as Attachments;

      return data;
    } catch (error) {
      console.error('Error is ', error);
    } finally {
      setLoading(false);
    }
  };

  const uploadFileToTheServer = async (file: RcFile | Blob) => {
    try {
      const { binaryData, originalFile } = await readDataAsArrayBuffer(file);
      const preSignedData = await getPresignedUrl(originalFile.name);

      await axiosInstance.put(preSignedData?.url ?? '', binaryData, {
        headers: {
          'x-ms-blob-type': 'BlockBlob',
          'Content-Type': originalFile.type,
          Authorization: undefined
        }
      });

      return {
        key: preSignedData?.key,
        name: originalFile.name
      };
    } catch (error) {
      console.error('Error is ', error);

      throw error;
    }
  };

  return {
    loading,
    getPresignedUrl,
    uploadFileToTheServer
  };
};

export default FileUploadService;
