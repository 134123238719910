import { Button as AntButton } from 'antd';
import { ButtonType } from 'antd/lib/button';
import React, { FC, ReactNode, Ref } from 'react';

interface ButtonProps {
  onClick?: () => void;
  children: ReactNode;
  htmlType?: 'reset' | 'submit' | 'button';
  type?: ButtonType;
  className?: string;
  icon?: ReactNode;
  disabled?: boolean;
  size?: 'small' | 'large';
  loading?: boolean;
  name?: string;
  target?: string;
  href?: string;
  buttonRef?: Ref<HTMLButtonElement>;
}

const Button: FC<ButtonProps> = (props) => {
  const {
    onClick,
    children,
    htmlType = 'button',
    className,
    icon,
    disabled,
    size,
    loading,
    name,
    target,
    href,
    type,
    buttonRef
  } = props;

  return (
    <div className={`button ${className}`}>
      <AntButton
        loading={!!loading}
        disabled={disabled}
        icon={icon}
        className={`${type} ${size}`}
        htmlType={htmlType}
        onClick={onClick}
        name={name}
        target={target}
        href={href}
        type={type}
        data-testid="button"
        ref={buttonRef}
        data-html2canvas-ignore="true"
      >
        {children}
      </AntButton>
    </div>
  );
};

export default Button;
