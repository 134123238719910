import React, { FC, ReactNode, useEffect } from 'react';
import { Modal as CustomModal, ModalProps as CustomModalProps } from 'antd';
import Button from 'src/shared/components/Button';
import './modal.scss';
import { LOCAL_ASSET_PATHS } from '../../constants/LocalAssetPath';

interface ModalProps extends CustomModalProps {
  open?: boolean;
  title?: ReactNode;
  onClose?: () => void;
  onIconClose?: () => void;
  onSubmit?: () => void;
  btnChildren?: ReactNode;
  closeBtnChildren?: ReactNode;
  timer?: number;
  closeImage?: boolean;
  isNotification?: boolean;
  buttonLoading?: boolean;
}

const Modal: FC<ModalProps> = ({ className, ...props }) => {
  const {
    btnChildren = 'Ok',
    open,
    title,
    children,
    onClose,
    onIconClose,
    onSubmit,
    timer,
    closeImage = true,
    isNotification = true,
    buttonLoading = false,
    closeBtnChildren,
    width,
    footer
  } = props;

  useEffect(() => {
    if (open && timer) {
      const modalCloseTimer = setTimeout(() => {
        handleModalClose();
      }, timer);

      return () => clearTimeout(modalCloseTimer);
    }
  }, [open, timer, onClose]);

  const handleModalSubmit = () => {
    onSubmit && onSubmit();
  };

  const handleModalClose = () => {
    onClose && onClose();
  };

  const handleIconClose = () => {
    onIconClose ? onIconClose() : handleModalClose();
  };

  return (
    <CustomModal
      title={title}
      footer={
        footer || (
          <div className="default-footer">
            <Button
              key="cancel"
              onClick={handleModalClose}
              size="large"
              className="cancel"
            >
              {closeBtnChildren || 'Cancel'}
            </Button>
            <Button
              key="submit"
              onClick={handleModalSubmit}
              htmlType="submit"
              type="primary"
              size="large"
              loading={buttonLoading}
            >
              {btnChildren}
            </Button>
          </div>
        )
      }
      open={open}
      className="custom-modal"
      centered
      closable={false}
      wrapClassName={`modal ${
        isNotification ? 'notification' : ''
      } ${className}`}
      {...props}
      width={width}
    >
      {closeImage && (
        <img
          src={`${LOCAL_ASSET_PATHS.CLOSE_ICON}`}
          alt="Close Image"
          className={`modal-image__close`}
          onClick={handleIconClose}
        />
      )}
      {children}
    </CustomModal>
  );
};

export default Modal;
