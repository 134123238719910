import { serializable, alias, primitive } from 'serializr';

export class LatestStatus {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('created_at', primitive()))
  createdAt?: number;

  @serializable(alias('updated_at', primitive()))
  updatedAt?: number;

  @serializable(alias('status', primitive()))
  status?: string;

  @serializable(alias('description', primitive()))
  description?: object;
}
