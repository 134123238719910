import { useState } from 'react';
import { deserialize } from 'serializr';
import axiosInstance from '../../interceptor/axiosInstance';
import { ApiRoutes } from '../../routes/routeConstants/apiRoutes';
import { InAppNotification } from 'src/models/InAppNotification/InAppNotification.model';
import { generatePath } from 'react-router-dom';
import { NotificationDetails } from '../../models/NotificationDetails/NotificationDetails';

const NotificationService = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [inAppNotificationData, setInAppNotificationData] = useState<
    InAppNotification | undefined
  >(undefined);

  const setInAppNotificationDataManually = (
    values: InAppNotification | NotificationDetails | undefined,
    dataFromSocket?: boolean
  ) => {
    if (values && dataFromSocket) {
      setInAppNotificationData((preValue) => {
        const notificationExists = preValue?.notifications?.some(
          (notification: NotificationDetails) => notification.id === values.id
        );

        if (!notificationExists) {
          return {
            ...preValue,
            notifications: [
              deserialize(NotificationDetails, values),
              ...(preValue?.notifications || [])
            ],
            unreadCount: (preValue?.unreadCount || 0) + 1,
            totalCount: (preValue?.totalCount || 0) + 1
          };
        }

        return preValue;
      });
    } else {
      values instanceof InAppNotification && setInAppNotificationData(values);
    }
  };

  const getNotifications = async (
    params?: { notification_id?: number },
    append?: boolean
  ) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(ApiRoutes.NOTIFICATIONS, {
        params: params
      });
      const data = deserialize(InAppNotification, response.data);

      if (append) {
        setInAppNotificationData({
          ...inAppNotificationData,
          notifications: [
            ...(inAppNotificationData?.notifications || []),
            ...(data?.notifications || [])
          ]
        });
      } else {
        setInAppNotificationData(data);
      }

      return data;
    } catch (error) {
      setInAppNotificationData(undefined);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const updateNotificationStatus = async (notificationId: number) => {
    try {
      await axiosInstance.patch(
        generatePath(ApiRoutes.NOTIFICATION_MARK_AS_READ, {
          id: notificationId
        })
      );
      return true;
    } catch (error) {
      console.error('Error updating notification status:', error);
      return false;
    }
  };

  return {
    loading,
    getNotifications,
    updateNotificationStatus,
    setInAppNotificationDataManually,
    inAppNotificationData
  };
};

export default NotificationService;
