import React, { FC, Ref } from 'react';
import { Upload } from 'antd';
import Note from 'src/shared/components/Note';
import './uploadPicture.scss';
import Button from '../Button';
import { UploadProps } from 'antd/lib/upload';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import FileUploadService from '../../../services/FileUploadService/fileUpload.service';
import { fileToBase64 } from '../../../shared/utils/fileToBase64';
import { RcFile } from 'antd/lib/upload';

interface UploadPictureProps extends UploadProps {
  placeholder?: string;
  title?: string;
  note?: string;
  notetitle?: string;
  base64Output?: boolean;
  buttonRef?: Ref<HTMLButtonElement>;
}

const UploadPicture: FC<UploadPictureProps> = (props) => {
  const {
    title,
    note,
    notetitle,
    placeholder = 'Max file size 10 MB',
    accept = 'image/png, image/jpeg',
    base64Output = false
  } = props;

  const { uploadFileToTheServer } = FileUploadService();

  const uploadFile = async (options: UploadRequestOption) => {
    if (typeof options.file === 'string') return;

    try {
      if (!base64Output) {
        const additionalData = await uploadFileToTheServer(options.file);
        options.onSuccess && options.onSuccess(additionalData);
      } else {
        const base64String = await fileToBase64(options.file);
        options.onSuccess &&
          options.onSuccess({
            filename: (options.file as RcFile).name,
            content: base64String,
            contentType: options.file.type
          });
      }
    } catch (error) {
      options.onError && options.onError(new Error());
    }
  };

  return (
    <div className="upload">
      {title && <div className="upload-title">{title}</div>}
      <Upload {...props} accept={accept} customRequest={uploadFile}>
        <div className="upload-container">
          <Button {...props} type="primary" size="small">
            Upload File
          </Button>
          <div className="upload-container__placeholder"> {placeholder}</div>
        </div>
      </Upload>
      {note && <Note note={note} title={notetitle} className="mt-2" />}
    </div>
  );
};

export default UploadPicture;
