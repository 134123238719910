import React, { ReactNode } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

interface Spinner {
  children?: ReactNode;
  size?: 'small' | 'large' | 'default';
  color?: 'primary' | 'secondary' | 'teritery';
  isLoadingOutlined?: boolean;
  loading?: boolean;
  tip?: string;
  fullscreen?: boolean;
  theme?: 'dark' | 'light';
}

const Spinner = (props: Spinner) => {
  const {
    children,
    size = 'default',
    color = 'primary',
    isLoadingOutlined = false,
    loading = true,
    tip,
    fullscreen = false,
    theme = 'dark'
  } = props;
  const antIcon = isLoadingOutlined ? <LoadingOutlined /> : undefined;

  return (
    <Spin
      tip={tip}
      spinning={loading}
      className={`${
        color === 'teritery'
          ? 'teritery'
          : color === 'secondary'
            ? 'secondary'
            : 'primary'
      } ${fullscreen ? 'fullscreen' : ''} ${
        theme === 'dark' ? 'dark' : 'light'
      }`}
      size={size}
      indicator={antIcon}
    >
      {children ? children : <div></div>}
    </Spin>
  );
};

export default Spinner;
