import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../../../context/AuthContext';
import RestrictAccess from '../RestrictedAccess';
import { User } from 'src/models/User/user.model';
import { AppRoutes } from 'src/routes/routeConstants/appRoutes';
import AuthService from 'src/services/AuthService/auth.service';
import { Tokens } from 'src/enums/tokens';

interface AuthenticationProps {
  allowedRoles?: string[];
  user?: User;
}

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const Authentication = (props: AuthenticationProps) => {
    const { allowedRoles } = props;
    const { authenticated } = AuthContext();
    const location = useLocation();
    const { refreshUser } = AuthService();

    useEffect(() => {
      if (!authenticated && location.pathname !== AppRoutes.LOGIN) {
        const refreshToken = localStorage.getItem(Tokens.REFRESH_TOKEN);
        if (!refreshToken) {
          localStorage.clear();
          sessionStorage.clear();
          window.location.replace(AppRoutes.LOGIN);
        } else {
          refreshUser();
        }
      }
    }, [props]);

    if (allowedRoles?.length) {
      const { user } = props;
      return allowedRoles.includes(user?.role ?? '') ? (
        children
      ) : (
        <RestrictAccess />
      );
    }
    return children;
  };

  return <Authentication />;
};

export const isAuthenticated = (component: JSX.Element) => {
  return RequireAuth({ children: component });
};

export default isAuthenticated;
