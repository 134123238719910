export const ApiRoutes = {
  BASE_URL: process.env.REACT_APP_API_BASE_URL + '/api/v1',
  USER_LOGIN: '/auth/login',
  SET_PASSWORD: '/auth/set-password',
  FORGOT_PASSWORD: '/auth/forgot-password',
  USERS: '/users',
  UPDATE_USER: '/users/:id',
  ATTACHMENT_PRESIGNED_URL: '/attachments/presigned-url',
  PROJECTS_SPECIFIC: '/reclamation-forms/:id',
  RECLAMATION_META: '/meta/reclamation-form',
  RECLAMATION_FORMS: '/reclamation-forms',
  ADMIN: '/meta/users/admin',
  PROJECT_INFO: '/analytics/projects',
  UPDATE_PROJECT: '/reclamation-forms/:id',
  RECYCLER: '/recycler',
  SCENARIO: '/scenarios',
  QUOTES: '/quotes',
  QUOTE: '/quote',
  SEND_QUOTE: '/send-quote-to-customer/:id',
  SEND_M1_BILL: '/mails/mohawk-one/:id',
  BOL_VIEW: '/reclamation-forms/:id/bols',
  BOL_EDIT: '/bol-view/:id',
  SEND_BOL: '/mails/bol/:id',
  CARBON_CERTIFICATE: '/mails/certificates/:id',
  RECYCLER_META: '/meta/recyclers/:id',
  BOL_DOWNLOAD: '/bol-view/:id',
  NOTIFICATIONS: '/notifications',
  NOTIFICATION_MARK_AS_READ: '/notifications/mark-as-read/:id',
  SUB_FORMS: '/reclamation-forms/:id/sub-forms',
  ACTIVITY_LOG: '/activity-logs',
  REPORTS: '/reports'
};
