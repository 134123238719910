import { useState } from 'react';
import { deserialize, serialize } from 'serializr';
import axiosInstance from '../../interceptor/axiosInstance';
import { ApiRoutes } from './../../routes/routeConstants/apiRoutes';
import { User } from '../../models/User/user.model';
import { generatePath } from 'react-router-dom';
import { Pagination } from '../../models/Pagination/pagination.model';
import { AuthContext } from '../../context/AuthContext';
import useParams from '../../shared/hooks/useParams';
import { ParamsObject } from '../../shared/types/paramObject.type';

const UserService = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [users, setUsers] = useState<User[]>([]);

  const [usersPagination, setUsersPagination] = useState<
    Pagination | undefined
  >();

  const { setAuthenticated } = AuthContext();

  const { convertQueryParamsToObject } = useParams();

  const fetchUsers = async (queryParams: URLSearchParams) => {
    const params: ParamsObject = convertQueryParamsToObject(queryParams);

    try {
      setLoading(true);
      const response = await axiosInstance.get(ApiRoutes.USERS, {
        params
      });
      const data = deserialize(User, response.data['users']) as User[];
      const pagination = deserialize(Pagination, response.data['meta']);
      setUsersPagination(pagination);
      setUsers(data);
    } catch (error) {
      setUsers([]);
    } finally {
      setLoading(false);
    }
  };

  const createUser = async (user: User) => {
    setButtonLoading(true);
    try {
      const payload = { user: serialize(User, user) };
      await axiosInstance.post(ApiRoutes.USERS, payload);
      setButtonLoading(false);
      return true;
    } catch (error) {
      setButtonLoading(false);
      return false;
    }
  };

  const updateStatus = async (user: User) => {
    try {
      setButtonLoading(true);
      const payload = { user: { is_active: !user.isActive } };
      const url = generatePath(ApiRoutes.UPDATE_USER, { id: user.id });
      await axiosInstance.patch(url, payload);
    } catch (error) {
      console.error('Error is ', error);
    } finally {
      setButtonLoading(false);
    }
  };

  const updateProfile = async (user: User) => {
    try {
      setButtonLoading(true);
      const payload = { user: serialize(User, user) };
      const url = generatePath(ApiRoutes.USERS, { id: user.id });
      const response = await axiosInstance.put(url, payload);
      const userData = deserialize(User, response.data['user']) as User;
      setAuthenticated(userData);
    } catch (error) {
      console.error('Error is ', error);
    } finally {
      setButtonLoading(false);
    }
  };

  return {
    loading,
    users,
    buttonLoading,
    createUser,
    fetchUsers,
    usersPagination,
    updateProfile,
    updateStatus
  };
};

export default UserService;
