export const AppRoutes = {
  APP_COMPONENTS: '/app-component',
  DASHBOARD: '/dashboard',
  USER_MANAGEMENT: '/user-management',
  LOGIN: '/login',
  INDEX: '/',
  SET_PASSWORD: '/auth/set-password',
  PROJECT_DETAILS_VIEW: '/project/:id/view',
  PROJECT_DETAILS_EDIT: '/project/:id/edit',
  QUOTE_COST: '/project/:id/quote-cost',
  ACTIVITY_LOG: '/activity-logs',
  REPORTS: '/reports'
};
