import { serializable, alias, primitive } from 'serializr';
import { AuthType } from 'src/enums/authType';

export class LoginUser {
  @serializable(alias('auth_type', primitive()))
  authType?: AuthType;

  @serializable(alias('email', primitive()))
  email?: string;

  @serializable(alias('password', primitive()))
  password?: string;

  rememberPassword?: boolean | boolean[];
}
