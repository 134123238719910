import { serializable, alias, primitive } from 'serializr';

export class Methods {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('created_at', primitive()))
  createdAt?: number;

  @serializable(alias('updated_at', primitive()))
  updatedAt?: number;

  @serializable(alias('method', primitive()))
  method?: string;

  @serializable(alias('area', primitive()))
  area?: number;

  @serializable(alias('weight', primitive()))
  weight?: number | string;
}
