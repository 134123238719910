import { State } from '../State/state.model';

import { Country } from '../Country/country.model';

import { serializable, alias, object, primitive } from 'serializr';

export class Address {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('created_at', primitive()))
  createdAt?: number;

  @serializable(alias('updated_at', primitive()))
  updatedAt?: number;

  @serializable(alias('street', primitive()))
  street?: string;

  @serializable(alias('city', primitive()))
  city?: string;

  @serializable(alias('zip', primitive()))
  zip?: string;

  @serializable(alias('country', object(Country)))
  country?: Country;

  @serializable(alias('state', object(State)))
  state?: State;
}
