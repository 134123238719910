import {
  serializable,
  alias,
  object,
  primitive,
  getDefaultModelSchema
} from 'serializr';
import { ReclamationForm } from '../ReclamationForm/reclamationForm.model';
import { User } from '../User/user.model';

export class ActivityLogs {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('created_at', primitive()))
  createdAt?: string;

  @serializable(alias('updated_at', primitive()))
  updatedAt?: string;

  @serializable(alias('action', primitive()))
  action?: string;

  @serializable(alias('item', primitive()))
  item?: string;

  @serializable(alias('description', primitive()))
  description?: string;

  reclamationForm?: ReclamationForm = {};

  @serializable(alias('admin', object(User)))
  admin?: User;

  constructor() {
    getDefaultModelSchema<ActivityLogs>(ActivityLogs)!.props.reclamationForm =
      alias('reclamation_form', object(ReclamationForm));
  }

  getDocumentName = (): string => {
    return this.description?.split(' ')[1] ?? ' ';
  };

  getQuoteCostName = (): string => {
    return this.description?.split('was')[0] ?? ' ';
  };
}
