import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ParamsObject } from '../types/paramObject.type';

const useParams = (
  fetchData?: (queryParams: URLSearchParams) => Promise<void>
) => {
  const [queryParams, setQueryParams] = useSearchParams();

  useEffect(() => {
    if (fetchData) fetchData(queryParams);
  }, [queryParams]);

  const updateParams = (paramsObject: ParamsObject) => {
    Object.entries(paramsObject).forEach(([key, value]) => {
      setQueryParams((params) => {
        params.set(key, value + '');
        return params;
      });
    });
  };

  const convertQueryParamsToObject = (queryParams: URLSearchParams) => {
    const params: ParamsObject = {};
    queryParams.forEach((value, key) => {
      params[key] = value;
    });
    return params;
  };

  const deleteQueyParams = (key: string) => {
    queryParams.delete(key);
    setQueryParams(queryParams);
  };

  return {
    queryParams,
    updateParams,
    deleteQueyParams,
    convertQueryParamsToObject
  };
};

export default useParams;
