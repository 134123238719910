import React, { FC, ReactNode } from 'react';
import { Select, SelectProps } from 'antd';
import { Field, ErrorMessage, FieldProps } from 'formik';
import Error from '../Error';
import { LabeledValue } from 'antd/es/select';
import Note from '../Note';
import { LOCAL_ASSET_PATHS } from '../../constants/LocalAssetPath';
import { LoadingOutlined } from '@ant-design/icons';

interface DropdownFieldProps {
  name: string;
  options?: SelectProps['options'];
  title?: string;
  placeholder?: string;
  onChange?: (
    value: string | number | LabeledValue,
    option: SelectProps | SelectProps['options']
  ) => void;
  note?: string;
  notetitle?: string;
  suffixIcon?: ReactNode;
  disabled?: boolean;
  mode?: 'tags' | 'multiple';
  showSearch?: boolean;
  loading?: boolean;
  optionFilterProp?: 'label' | 'value';
  allowClear?: boolean;
  size?: 'small';
  dropdownRender?: (originNode: ReactNode) => ReactNode;
  defaultValue?: string;
  value?: string;
  showError?: boolean;
  open?: boolean;
}

export const DropDownField: FC<DropdownFieldProps> = (props) => {
  const {
    title,
    options,
    onChange,
    suffixIcon = (
      <img alt="down-arrow-icon" src={LOCAL_ASSET_PATHS.DOWN_ARROW_ICON} />
    ),
    loading,
    size,
    dropdownRender,
    value,
    note,
    notetitle
  } = props;
  return (
    <div className="dropdown-field">
      {title && <div className="field-title">{title}</div>}
      <Select
        {...props}
        options={options}
        loading={loading}
        suffixIcon={!loading ? suffixIcon : <LoadingOutlined />}
        getPopupContainer={(trigger) => trigger.parentNode}
        dropdownRender={(menu) =>
          !dropdownRender && size === 'small' ? (
            <div className="small-dropdown">{menu}</div>
          ) : dropdownRender ? (
            <>{dropdownRender(menu)}</>
          ) : (
            menu
          )
        }
        size={size}
        onChange={(value) => {
          if (onChange) {
            onChange(value, options);
          }
        }}
        value={value ? value : undefined}
      />
      {note && <Note note={note} title={notetitle} className="mt-2" />}
    </div>
  );
};

export const DropdownFieldWithFormik: FC<DropdownFieldProps> = (props) => {
  const {
    name,
    title,
    options,
    onChange,
    note,
    notetitle,
    suffixIcon = (
      <img alt="down-arrow-icon" src={LOCAL_ASSET_PATHS.DOWN_ARROW_ICON} />
    ),
    loading,
    optionFilterProp = 'label',
    size,
    dropdownRender,
    showError = true
  } = props;

  return (
    <Field name={name}>
      {({ field, form: { setFieldValue }, meta }: FieldProps) => (
        <div className={`dropdown-field`}>
          {title && <div className="field-title">{title}</div>}
          <Select
            status={meta.touched && meta.error ? 'error' : ''}
            {...field}
            {...props}
            getPopupContainer={(trigger) => trigger.parentNode}
            options={options}
            onChange={(value) => {
              setFieldValue(name, value);
              if (onChange) {
                onChange(value, options);
              }
            }}
            value={field.value}
            suffixIcon={!loading ? suffixIcon : <LoadingOutlined />}
            optionFilterProp={optionFilterProp}
            dropdownRender={(menu) =>
              !dropdownRender && size === 'small' ? (
                <div className="small-dropdown">{menu}</div>
              ) : dropdownRender ? (
                <>{dropdownRender(menu)}</>
              ) : (
                menu
              )
            }
          />
          {showError && (
            <div className="error-holder">
              <ErrorMessage name={name}>
                {(message: string) => <Error message={message} />}
              </ErrorMessage>
            </div>
          )}
          {note && <Note note={note} title={notetitle} className="mt-2" />}
        </div>
      )}
    </Field>
  );
};
