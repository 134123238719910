import { FC } from 'react';
import { LOCAL_ASSET_PATHS } from 'src/shared/constants/LocalAssetPath';

interface PopOverContent {
  onUpdateProfile: () => void;
}

const PopOverContent: FC<PopOverContent> = (props) => {
  const { onUpdateProfile } = props;

  return (
    <div className="cursor-pointer" onClick={onUpdateProfile}>
      <img src={LOCAL_ASSET_PATHS.UPDATE_PROFILE_ICON} />
      <span className="ml-4">Update Profile</span>
    </div>
  );
};

export default PopOverContent;
