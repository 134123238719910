export const REMOTE_ASSET_PATHS = {
  // Icons
  MOHAWK_COMMERCIAL_LOGO:
    'https://s7d4.scene7.com/is/image/MohawkResidential/Mohawk_Group_logo_red?fmt=png-alpha',
  MOHAWK_RESIDENTIAL_LOGO:
    'https://s7d4.scene7.com/is/image/MohawkResidential/mohawk_color_logo?fmt=png-alpha',
  MOHAWK_RECOVER_PROGRAM_LOGO:
    'https://s7d4.scene7.com/is/image/MohawkResidential/recover_recycling_program_color_tagline_logo-3?fmt=png-alpha',
  MOHAWK_ROCOVER_LOGO:
    'https://s7d4.scene7.com/is/image/MohawkResidential/recover_all_white_logo?fmt=png-alpha',
  INSTAGRAM_SOCIAL_MEDIA_ICON:
    'https://s7d4.scene7.com/is/image/MohawkResidential/instagram?fmt=png-alpha',
  FACEBOOK_SOCIAL_MEDIA_ICON:
    'https://s7d4.scene7.com/is/image/MohawkResidential/Facebook?fmt=png-alpha',
  TWITTER_SOCIAL_MEDIA_ICON:
    'https://s7d4.scene7.com/is/image/MohawkResidential/twitter?fmt=png-alpha'

  // Images
};
