import MOHAWK_COMMERCIAL_SVG from 'src/assets/icons/mohawk-group.svg';
import MOHAWK_RESEDENTIAL_SVG from 'src/assets/icons/mohawk-flooring.svg';
import RIGHT_ARROW_ICON from 'src/assets/icons/right-arrow.svg';
import DOWNLOAD_ICON from 'src/assets/icons/download-icon.svg';
import EMAIL_ICON from 'src/assets/icons/email-icon.svg';
import PHONE_ICON from 'src/assets/icons/phone-icon.svg';
import CLOSE_ICON from 'src/assets/icons/close-icon.svg';
import RECYCLED_ICON from 'src/assets/icons/recycled-icon.svg';
import REUSE_ICON from 'src/assets/icons/reuse-icon.svg';
import USA_FLAG from 'src/assets/icons/usa-flag.svg';
import CANADA_FLAG from 'src/assets/icons/canada-flag.svg';
import CALENDAR_ICON from 'src/assets/icons/calendar-icon.png';
import DOWN_ARROW_ICON from 'src/assets/icons/drop-down-arrow-icon.png';
import RIGHT_ARROW_BULLET_ICON from 'src/assets/icons/right-arrow-bullet-icon.png';
import RECYCLE_CERTIFICATE from 'src/assets/icons/recycle-certification.png';
import SUCCESS_ICON from 'src/assets/icons/success-icon.svg';
import ACCEPTED_PROJECTS_ICON from 'src/assets/analytics/accepted-projects-icon.svg';
import DECLINED_PROJECTS_ICON from 'src/assets/analytics/declined-icon-icon.svg';
import EXPIRED_PROJECTS_ICON from 'src/assets/analytics/expired-project-icon.svg';
import INPROGRESS_PROJECTS_ICON from 'src/assets/analytics/inprogress-icon.svg';
import TOTAL_PROJECTS_ICON from 'src/assets/analytics/total-project-icon.svg';
import CALENDER_LIGHT_ICON from 'src/assets/icons/calendar-icon-grey-light.svg';
import LEFT_PAGINATION_FAST_ICON from 'src/assets/icons/left-pagination-fast-arrow-icon.svg';
import RIGHT_PAGINATION_FAST_ICON from 'src/assets/icons/right-pagination-fast-arrow-icon.svg';

import HAMBURGER_ICON from 'src/assets/icons/topbar/hamburger-icon.svg';
import SEARCH_ICON from 'src/assets/icons/search-icon-gray-dark.svg';
import NOTIFICATION_ICON from 'src/assets/icons/notifications-icon.svg';
import UPDATE_PROFILE_ICON from 'src/assets/icons/topbar/update-profile-icon.svg';
import ARROW_DOWN_ICON from 'src/assets/icons/topbar/arrow-down.svg';
import ARROW_UP_ICON from 'src/assets/icons/topbar/arrow-up.svg';
import MOHAWK_LOGO from 'src/assets/icons/mohawk-logo.svg';
import DELETE_ICON from 'src/assets/icons/delete-icon-red.svg';
import EDIT_ICON from 'src/assets/icons/edit-icon-green.svg';

export const LOCAL_ASSET_PATHS = {
  // Icons
  MOHAWK_COMMERCIAL_SVG,
  MOHAWK_RESEDENTIAL_SVG,
  RIGHT_ARROW_ICON,
  DOWNLOAD_ICON,
  EMAIL_ICON,
  PHONE_ICON,
  CLOSE_ICON,
  RECYCLED_ICON,
  REUSE_ICON,
  USA_FLAG,
  CANADA_FLAG,
  CALENDAR_ICON,
  DOWN_ARROW_ICON,
  RIGHT_ARROW_BULLET_ICON,
  SUCCESS_ICON,
  HAMBURGER_ICON,
  SEARCH_ICON,
  NOTIFICATION_ICON,
  UPDATE_PROFILE_ICON,
  ARROW_DOWN_ICON,
  ARROW_UP_ICON,
  MOHAWK_LOGO,
  ACCEPTED_PROJECTS_ICON,
  DECLINED_PROJECTS_ICON,
  EXPIRED_PROJECTS_ICON,
  INPROGRESS_PROJECTS_ICON,
  TOTAL_PROJECTS_ICON,
  CALENDER_LIGHT_ICON,
  LEFT_PAGINATION_FAST_ICON,
  RIGHT_PAGINATION_FAST_ICON,
  DELETE_ICON,
  EDIT_ICON,

  // Images
  RECYCLE_CERTIFICATE
};
