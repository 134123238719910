import React, { FC } from 'react';
import { Layout, Menu } from 'antd';
import { REMOTE_ASSET_PATHS } from 'src/shared/constants/RemoteAssetPath';
import menuItems from './menuItems';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoutes } from 'src/routes/routeConstants/appRoutes';
import AuthService from 'src/services/AuthService/auth.service';
import { LOCAL_ASSET_PATHS } from 'src/shared/constants/LocalAssetPath';
import './sidebar.scss';

const { Sider } = Layout;

interface SidebarProps {
  collapsed: boolean;
}

const Sidebar: FC<SidebarProps> = (props) => {
  const { collapsed } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = AuthService();

  if (location.pathname === AppRoutes.INDEX) navigate(AppRoutes.DASHBOARD);

  const onMenuItemClick = (item: { key: string }) => {
    if (item.key === 'logout') {
      logout();
      return;
    }
    navigate(item.key);
  };

  const currentYear = new Date().getFullYear();

  const activePath = '/' + location.pathname.split('/').pop();

  return (
    <Sider
      width={250}
      className="sidebar"
      trigger={null}
      collapsible
      collapsed={collapsed}
    >
      <div className="sidebar__logo-container">
        <img
          data-testid="logo"
          className="logo"
          src={REMOTE_ASSET_PATHS.MOHAWK_ROCOVER_LOGO}
        />
      </div>
      <Menu
        mode="inline"
        onClick={onMenuItemClick}
        defaultSelectedKeys={[activePath ?? AppRoutes.DASHBOARD]}
        selectedKeys={[activePath ?? AppRoutes.DASHBOARD]}
        items={menuItems}
      />
      <div className="sidebar__copywright">
        <img src={LOCAL_ASSET_PATHS.MOHAWK_LOGO} alt="Mohawk Logo" />
        <p className="mt-4">© {currentYear} Mohawk Industries, INC</p>
      </div>
    </Sider>
  );
};

export default Sidebar;
