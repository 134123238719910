import { Address } from '../Address/address.model';
import { Certificates } from '../Certificates/certificates.model';
import { serializable, alias, object, list, primitive } from 'serializr';
import { User } from '../User/user.model';

const certificateHandler = {
  beforeDeserialize: function (
    callback: (arg0: Error | null, arg1: boolean | undefined) => void,
    jsonValue: { toString: () => string },
    jsonParentValue: { [key: string]: string }
  ) {
    try {
      const certificate = jsonParentValue['certificates'];
      if (certificate && certificate.length > 0) {
        callback(null, true);
      } else {
        callback(null, false);
      }
    } catch (error) {
      callback(new Error(), false);
    }
  }
};

const onSitePersonHandler = {
  beforeDeserialize: function (
    callback: (arg0: Error | null, arg1: boolean | undefined) => void,
    jsonValue: { toString: () => string },
    jsonParentValue: { [key: string]: string }
  ) {
    try {
      const onsitePerson = jsonParentValue['onsitePerson'];
      if (onsitePerson) {
        callback(null, true);
      } else {
        callback(null, false);
      }
    } catch (error) {
      callback(new Error(), false);
    }
  }
};

export class Project {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('created_at', primitive()))
  createdAt?: number;

  @serializable(alias('updated_at', primitive()))
  updatedAt?: number;

  @serializable(alias('name', primitive()))
  name?: string;

  @serializable(alias('certificates', list(object(Certificates))))
  certificates?: Certificates[] = [];

  certificateValue?: number[];

  @serializable(
    alias('is_certificates_available', primitive(certificateHandler))
  )
  isCertificatesAvailable?: boolean;

  @serializable(alias('onsite_person', object(User)))
  onsitePerson?: User;

  @serializable(
    alias('is_on_site_person_available', primitive(onSitePersonHandler))
  )
  isOnSitePersonAvailable?: boolean;

  @serializable(alias('address', object(Address)))
  address?: Address;
}
