import React, { useState } from 'react';
import Sidebar from 'src/shared/components/Sidebar';
import { Layout } from 'antd';
import Topbar from 'src/shared/components/Topbar';
import { Outlet } from 'react-router-dom';

const { Content } = Layout;

const AdminLayout = () => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapse = () => {
    setCollapsed((collapsed) => !collapsed);
  };

  return (
    <Layout>
      <Sidebar collapsed={collapsed} />
      <Layout>
        <Topbar setCollapsed={toggleCollapse} />
        <Content>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
