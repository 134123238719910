import { serializable, alias, primitive } from 'serializr';

export class Channel {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('created_at', primitive()))
  createdAt?: number;

  @serializable(alias('updated_at', primitive()))
  updatedAt?: number;

  @serializable(alias('channel', primitive()))
  channel?: string;
}
