import * as Yup from 'yup';

export const userFormFormValidation = Yup.object().shape({
  firstName: Yup.string().required('First Name is required.'),
  lastName: Yup.string().required('Last Name is required.'),
  email: Yup.string()
    .required('Email is required')
    .email('Please enter valid email address'),
  role: Yup.string().required('Role is required.')
});
