import React, { FC } from 'react';
import 'src/shared/components/Note/note.scss';

interface NoteProps {
  note: string;
  title?: string;
  noteSize?: 'small' | 'large';
  className?: string;
}

const Note: FC<NoteProps> = (props) => {
  const { note, title, noteSize = 'small', className } = props;

  return (
    <div
      className={`note ${
        noteSize === 'small' ? 'note--small' : 'note--large'
      } ${className}`}
    >
      {title && <span className="note__bold">{title}</span>}
      {note}
    </div>
  );
};

export default Note;
