import { serializable, alias, primitive, object, list } from 'serializr';
import { Question } from '../Question/question.model';

export class FlooringType {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('created_at', primitive()))
  createdAt?: number;

  @serializable(alias('updated_at', primitive()))
  updatedAt?: number;

  @serializable(alias('name', primitive()))
  name?: string;

  @serializable(alias('applicable_for', primitive()))
  applicableFor?: string;

  @serializable(alias('area_unit', primitive()))
  areaUnit?: string;

  @serializable(alias('factor_to_pound', primitive()))
  factorToPound?: string;

  @serializable(alias('question', list(object(Question))))
  question?: Question[];

  @serializable(alias('questions', list(object(Question))))
  questionsMeta?: Question[];
}
