import { serializable, alias, primitive } from 'serializr';

export class Pickup {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('created_at', primitive()))
  createdAt?: number;

  @serializable(alias('updated_at', primitive()))
  updatedAt?: number;

  @serializable(alias('type', primitive()))
  type?: string;

  @serializable(alias('date', primitive()))
  date?: number;

  @serializable(alias('schedule', primitive()))
  schedule?: string;
}
