import React, { FC, useEffect, useRef, useState } from 'react';
import { Layout, Button, Popover, Avatar, Divider, Badge, Form } from 'antd';
import { AuthContext } from 'src/context/AuthContext';
import PopOverContent from './PopOverContent';
import getInitials from 'src/shared/utils/getInitials';
import { LOCAL_ASSET_PATHS } from 'src/shared/constants/LocalAssetPath';
import UserService from 'src/services/UserService/user.service';
import { User } from '../../../models/User/user.model';
import './topbar.scss';
import { Formik } from 'formik';
import { userFormFormValidation } from '../../../views/UserManagement/userFormFormValidation';
import Modal from '../Modal';
import UserForm from '../../../views/UserManagement/UserForm';
import InAppNotification from './InAppNotification';
import { initSocket } from '../../../shared/utils/initSocket';
import NotificationService from 'src/services/NotificationService/notification.service';
import { SocketEvents } from 'src/enums/socketEvents.enum';
import { NotificationDetails } from 'src/models/NotificationDetails/NotificationDetails';
import { Socket } from 'socket.io-client';
import { DefaultEventsMap } from '@socket.io/component-emitter';
import { VirtuosoHandle } from 'react-virtuoso';

const { Header } = Layout;

interface TopbarProps {
  setCollapsed: () => void;
}

const Topbar: FC<TopbarProps> = (props) => {
  const { setCollapsed } = props;

  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);

  const virtuosoRef: React.RefObject<VirtuosoHandle> | undefined = useRef(null);

  const { user } = AuthContext();

  const { updateProfile, buttonLoading } = UserService();

  const {
    getNotifications,
    updateNotificationStatus,
    inAppNotificationData,
    setInAppNotificationDataManually,
    loading
  } = NotificationService();

  useEffect(() => {
    getNotifications();
    const socket: Socket<DefaultEventsMap, DefaultEventsMap> = initSocket();

    socket.on(
      SocketEvents.FORM_REDIRECT,
      (data: { notification: NotificationDetails }) => {
        data['notification'] &&
          setInAppNotificationDataManually(data['notification'], true);
      }
    );

    return () => {
      socket.off(SocketEvents.FORM_REDIRECT, () => {});
    };
  }, []);

  const onProfileUpdate = () => toggleModal();

  const toggleModal = () => setShowUpdateModal((prevState) => !prevState);

  const updateUserData = async (data: User) => {
    await updateProfile(data);
    toggleModal();
  };

  const name = `${user?.firstName ?? ''} ${user?.lastName ?? ''}`;
  const email = user?.email;

  const userInitials = getInitials(name);
  const imageUrl = user?.photo?.url;

  return (
    <>
      <Header className="header">
        <Button data-testid="hamberger-icon" type="text" onClick={setCollapsed}>
          <img src={LOCAL_ASSET_PATHS.HAMBURGER_ICON} />
        </Button>
        <div className="header__right-section">
          <div className="header__right-section__button">
            <Popover
              placement="bottomRight"
              content={
                <InAppNotification
                  virtuosoRef={virtuosoRef}
                  inAppNotificationData={inAppNotificationData}
                  getNotifications={getNotifications}
                  updateNotificationStatus={updateNotificationStatus}
                  loading={loading}
                />
              }
              overlayClassName="notification-popover"
              showArrow={true}
              onOpenChange={(visible: boolean) => {
                !visible &&
                  virtuosoRef.current?.scrollToIndex({
                    index: 0
                  });
              }}
            >
              <Badge count={inAppNotificationData?.unreadCount}>
                <img
                  src={LOCAL_ASSET_PATHS.NOTIFICATION_ICON}
                  className="icon"
                />
              </Badge>
            </Popover>
          </div>
          <Divider type="vertical" />
          <div className="header__right-section__profile">
            {imageUrl ? (
              <Avatar size="large" className="mr-2" src={imageUrl} />
            ) : (
              <Avatar size="large" className="mr-2">
                {userInitials}
              </Avatar>
            )}
            <Popover
              placement="bottomRight"
              content={<PopOverContent onUpdateProfile={onProfileUpdate} />}
              className="profile-popover"
              showArrow={false}
            >
              <div className="profile-popover__content mr-2">
                <div className="profile-popover__content__name">{name}</div>
                <span className="profile-popover__content__email">{email}</span>
              </div>
              <div>
                <img
                  src={LOCAL_ASSET_PATHS.ARROW_DOWN_ICON}
                  className="arrow-icon"
                />
              </div>
            </Popover>
          </div>
        </div>
      </Header>
      <Formik
        initialValues={user ?? new User()}
        validationSchema={userFormFormValidation}
        onSubmit={updateUserData}
        enableReinitialize
      >
        {({ handleSubmit, resetForm }) => (
          <Form>
            <Modal
              open={showUpdateModal}
              onSubmit={handleSubmit}
              onClose={toggleModal}
              afterClose={resetForm}
              isNotification={false}
              title="Update Profile"
              btnChildren="Update"
              buttonLoading={buttonLoading}
              className="user-modal"
            >
              <UserForm />
            </Modal>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Topbar;
