import React, { lazy } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { RouterProps } from 'src/shared/types/route.type';
import { AppRoutes } from './routeConstants/appRoutes';
import AdminLayout from 'src/shared/layout/AdminLayout';
import LazySuspense from 'src/shared/components/LazySuspense';
import isAuthenticated from 'src/shared/components/HOC/requireAuth';
import { ProjectActionTypeEnum } from 'src/enums/projectActionType.enum';

const Dashboard = lazy(() => import('src/views/Dashboard'));
const AppComponents = lazy(() => import('src/views/AppComponents'));
const Login = lazy(() => import('src/views/Login'));
const SetPassword = lazy(() => import('src/views/SetPassword'));
const UserManagement = lazy(() => import('src/views/UserManagement'));
const ProjectDetails = lazy(() => import('src/views/ProjectDetails'));
const QuoteCost = lazy(() => import('src/views/ProjectDetails/QuoteCost'));
const PageNotFound = lazy(() => import('src/views/PageNotFound'));
const ActivityLog = lazy(() => import('src/views/ActivityLog'));
const Reports = lazy(() => import('src/views/Reports'));

export const appHistory = createBrowserHistory();
const AppRouter = () => {
  const routes: RouterProps[] = [
    {
      path: AppRoutes.SET_PASSWORD,
      component: <SetPassword />
    },
    {
      path: AppRoutes.LOGIN,
      component: <Login />
    },
    {
      path: AppRoutes.INDEX,
      component: isAuthenticated(<AdminLayout />),
      children: [
        {
          path: AppRoutes.DASHBOARD,
          component: <Dashboard />
        },
        {
          path: AppRoutes.USER_MANAGEMENT,
          component: <UserManagement />
        },
        {
          path: AppRoutes.PROJECT_DETAILS_VIEW,
          component: <ProjectDetails status={ProjectActionTypeEnum.VIEW} />
        },
        {
          path: AppRoutes.PROJECT_DETAILS_EDIT,
          component: <ProjectDetails status={ProjectActionTypeEnum.EDIT} />
        },
        {
          path: AppRoutes.QUOTE_COST,
          component: <QuoteCost />
        },
        {
          path: AppRoutes.ACTIVITY_LOG,
          component: <ActivityLog />
        },
        {
          path: AppRoutes.REPORTS,
          component: <Reports />
        },
        {
          path: '*',
          component: <PageNotFound />
        }
      ]
    },
    {
      path: '*',
      component: <PageNotFound />
    }
  ];

  if (process.env.REACT_APP_UNDER_DEVELOPMENT) {
    routes.push({
      path: AppRoutes.APP_COMPONENTS,
      component: <AppComponents />
    });
  }

  return (
    <div>
      <BrowserRouter>
        <Routes>
          {routes?.map((route, index) => {
            return (
              <Route
                key={index}
                path={route?.path}
                element={<LazySuspense>{route.component}</LazySuspense>}
              >
                {route.children &&
                  route.children.map((childRoute) => (
                    <Route
                      key={childRoute.path}
                      path={childRoute.path}
                      element={
                        <LazySuspense>{childRoute.component}</LazySuspense>
                      }
                    />
                  ))}
              </Route>
            );
          })}
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default AppRouter;
