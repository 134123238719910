import { serializable, alias, primitive, list, object } from 'serializr';
import { State } from '../State/state.model';

export class Country {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('created_at', primitive()))
  createdAt?: number;

  @serializable(alias('updated_at', primitive()))
  updatedAt?: number;

  @serializable(alias('name', primitive()))
  name?: string;

  @serializable(alias('phone_code', primitive()))
  phoneCode?: string;

  @serializable(alias('is_active', primitive()))
  isActive?: boolean;

  @serializable(alias('states', list(object(State))))
  states?: State[] = [];
}
