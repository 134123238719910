import { FC } from 'react';
import './inAppNotification.scss';
import { Empty } from 'antd';
import { NotificationDetails } from '../../../../models/NotificationDetails/NotificationDetails';
import { AppRoutes } from '../../../../routes/routeConstants/appRoutes';
import { generatePath } from 'react-router-dom';
import { useRedirect } from '../../../../shared/hooks/useRedirect';
import Spinner from '../../Spinner';
import { InAppNotification as InAppNotificationModel } from 'src/models/InAppNotification/InAppNotification.model';
import moment from 'moment';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';

interface InAppNotificationProps {
  virtuosoRef: React.RefObject<VirtuosoHandle> | undefined;
  inAppNotificationData: InAppNotificationModel | undefined;
  getNotifications: (
    params?: { notification_id?: number | undefined },
    append?: boolean
  ) => Promise<false | InAppNotificationModel>;
  updateNotificationStatus: (notificationId: number) => Promise<boolean>;
  loading: boolean;
}

const InAppNotification: FC<InAppNotificationProps> = (props) => {
  const {
    virtuosoRef,
    inAppNotificationData,
    getNotifications,
    updateNotificationStatus,
    loading
  } = props;

  const { changePath } = useRedirect();

  const handleNotificationClick = async (notification: NotificationDetails) => {
    if (!notification) return;

    const path = generatePath(AppRoutes.PROJECT_DETAILS_VIEW, {
      id: notification.formId
    });

    changePath(path);

    if (!notification.isRead) {
      const updateResponse =
        notification.id && (await updateNotificationStatus(notification.id));
      updateResponse &&
        (await getNotifications()) &&
        virtuosoRef &&
        virtuosoRef.current?.scrollToIndex({
          index: 0
        });
    }
  };

  const handleScroll = () => {
    if (
      !inAppNotificationData ||
      !inAppNotificationData.notifications ||
      !inAppNotificationData.totalCount ||
      inAppNotificationData.notifications.length >=
        inAppNotificationData.totalCount ||
      loading
    )
      return;

    const notificationId = {
      notification_id: Math.min(
        ...(inAppNotificationData.notifications.map(
          (notification: InAppNotificationModel) => notification.id
        ) as number[])
      )
    };

    getNotifications(notificationId, true);
  };

  if (!inAppNotificationData || !inAppNotificationData?.notifications) return;

  return (
    <div className="inapp-notification">
      {(!inAppNotificationData ||
        inAppNotificationData.notifications.length <= 0) && (
        <Spinner loading={loading}>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            data-test-id="empty-notification"
          />
        </Spinner>
      )}
      <Virtuoso
        data-test-id="virtuoso-component"
        ref={virtuosoRef}
        data={inAppNotificationData?.notifications}
        itemContent={(index: number, notification: NotificationDetails) => (
          <div
            key={index}
            className={`inapp-notification__container ${
              !notification.isRead && 'inapp-notification__container--bold'
            }`}
            onClick={() => handleNotificationClick(notification)}
            data-test-id="notification"
          >
            <p className="inapp-notification__container__title">
              {notification.title || ''}
            </p>
            <p className="inapp-notification__container__content">
              {notification.content || ''}
            </p>
            <p className="inapp-notification__container__updated-at">
              {moment(notification.createdAt).fromNow() || ''}
            </p>
          </div>
        )}
        style={{ width: '100%', height: '40vh' }}
        endReached={handleScroll}
      />
      {loading && <Spinner />}
    </div>
  );
};

export default InAppNotification;
