import { serializable, alias, primitive, object } from 'serializr';
import { ReclamationForm } from '../ReclamationForm/reclamationForm.model';
import { User } from '../User/user.model';

export class NotificationDetails {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('created_at', primitive()))
  createdAt?: number;

  @serializable(alias('updated_at', primitive()))
  updatedAt?: number;

  @serializable(alias('deleted_at', primitive()))
  deletedAt?: number;

  @serializable(alias('user', object(User)))
  user?: User;

  @serializable(alias('title', primitive()))
  title?: string;

  @serializable(alias('content', primitive()))
  content?: string;

  @serializable(alias('is_read', primitive()))
  isRead?: boolean;

  @serializable(alias('form_id', primitive()))
  formId?: number;

  @serializable(alias('form', object(ReclamationForm)))
  form?: ReclamationForm;

  @serializable(alias('type', primitive()))
  type?: string;
}
