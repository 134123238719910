import React, {
  useContext,
  createContext,
  useState,
  Dispatch,
  SetStateAction,
  ReactElement
} from 'react';
import { User } from 'src/models/User/user.model';

export interface AuthState {
  authenticated?: boolean;
  user?: User;
  accessToken?: string;
}

type SetAuthState = Dispatch<SetStateAction<AuthState>>;

type AuthContentProps = [AuthState, SetAuthState];

// Define the default context state
const initialValues: AuthState = {
  authenticated: false,
  user: new User(),
  accessToken: ''
};

// Create the context
const AuthContent = createContext<AuthContentProps | undefined>(undefined);

// Create method to use context
const AuthContext = () => {
  const context = useContext<AuthContentProps | undefined>(AuthContent);
  if (!context) {
    throw new Error(`useMeContext must be used within a MeContextProvider`);
  }
  const [auth, setAuth] = context;

  const setAuthenticated = (user?: User, accessToken?: string) => {
    setAuth((auth) => ({
      ...auth,
      authenticated: true,
      user,
      accessToken
    }));
  };

  const removeAuthenticated = () => {
    setAuth({ authenticated: false, user: undefined, accessToken: undefined });
  };

  return {
    ...auth,
    setAuthenticated,
    removeAuthenticated
  };
};

interface AuthProviderProps {
  children: ReactElement;
}

// Create the context provider
const AuthProvider = (props: AuthProviderProps) => {
  const [auth, setAuth] = useState<AuthState>(initialValues);
  return <AuthContent.Provider value={[auth, setAuth]} {...props} />;
};

export { AuthProvider, AuthContext };
