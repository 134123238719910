import { serializable, alias, primitive } from 'serializr';

export class ApprovalDetails {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('created_at', primitive()))
  createdAt?: number;

  @serializable(alias('updated_at', primitive()))
  updatedAt?: number;

  @serializable(alias('delivery_date', primitive()))
  deliveryDate?: number;

  @serializable(alias('acceptance_date', primitive()))
  acceptanceDate?: number;

  @serializable(alias('signature', primitive()))
  signature?: string;

  @serializable(alias('name', primitive()))
  name?: string;

  @serializable(alias('company_name', primitive()))
  companyName?: string;

  @serializable(alias('title', primitive()))
  title?: string;
}
